import PropTypes from 'prop-types'
import React from 'react'
import logo from '../images/logo.svg'

const Header = ({ siteTitle }) => (
	<div className="container center has-text-centered">
	<img src={logo} alt="gatsby-logo" className="is-text-centered"/>
	</div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
